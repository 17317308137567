import * as React from "react"
import { Link } from 'gatsby-plugin-intl';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import HeadSeo from "@/components/common/Head";

import BackGround from '@/images/404/background.png';

const NotFoundPage = () => {
  return (
    <div>
      <Header />

      <main className="flex justify-center items-center h-screen bg-[url:var(--bg-image)] bg-[position:center_-80px] md:bg-center bg-no-repeat mix-blend-multiply"
        style={{ '--bg-image': `url(${BackGround})` }}
      >
        <div className="max-w-[520px] w-full px-4">
          <div className="text-center rounded-2xl bg-white/20 backdrop-blur pt-6 px-4 pb-4 sm:p-6">
            <div className="mb-1 text-8xl sm:text-9xl font-bold text-center">404</div>
            <div className="font-medium">Oops, seems like nothing interesting here</div>
          </div>
          <div className="mt-1">
            <Link to="/" className="flex items-center justify-center h-16 w-full bg-black rounded-2xl text-white font-medium transition-all hover:opacity-90">Go to home page</Link>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default NotFoundPage

export const Head = () => <HeadSeo title="Page not found" />
